import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify,
  useDataProvider,
  PublicFieldProps,
  InjectedFieldProps,
  EditProps,
  Loading,
  EditButton,
  BooleanInput,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { validateSubdivisionCreation } from './subDivisionValidation';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { State } from 'components/State';
import SimpleForm from 'components/SimpleForm';
import Typography from '@material-ui/core/Typography';
import { batchBuilderSupervisor } from 'services/auth';
import {
  RouteDto,
  SpSubdivisionNameInterface,
  SubdivisionDto,
  SubdivisionInterface,
} from '@vatos-pas/common';

import {
  CAN_EDIT_SP_POTENTIAL_MATCHES,
  CAN_SEE_SP_POTENTIAL_MATCHES,
  CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW,
} from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { getBuilderSupervisors } from 'services/regions';
import {
  SpMatchesDefaultModal,
  SpMatchesDefaultTableList,
  SpMatchesDefaultTableRow,
  SpMatchesError,
} from 'components/SpMatchesDefaultTable';
import { Autocomplete } from '@material-ui/lab';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import InfoDialog from 'components/InfoDialog';
import { SupervisorInput } from '../components/SupervisorInput';
import { WarrantyStatusChoices } from '../constants';
import { RouteInput } from '../components/RouteInput';
import { findRouteByRegion } from 'services/route';
import BoardCostFields from '../components/BoardCostFields';

type BumpoutTimesheetBooleanInputProps = PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface>;

const BumpoutTimesheetBooleanInput = (
  props: BumpoutTimesheetBooleanInputProps,
) => {
  const { values } = useFormState();
  const classes = useStyles();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  return (
    <>
      <BooleanInput
        {...props}
        disabled={props.record?.reqBumpoutTimesheet}
        fullWidth
        onChange={() => {
          if (!values.reqBumpoutTimesheet) {
            setIsConfirmModalOpen(true);
          }
        }}
        className={classes.bumpoutTimesheetFlow}
        source="reqBumpoutTimesheet"
      />
      <InfoDialog
        title="Attention!"
        handleClose={() => {
          setIsConfirmModalOpen(false);
        }}
        open={isConfirmModalOpen}
        content="Activating this setting is a permanent change with no option to revert. All jobs will progress through the new bump flows if this change is made. Please be aware before saving."
      />
    </>
  );
};

type ReferencedInputsProps = {
  setSelectedRoute: React.Dispatch<React.SetStateAction<RouteDto | null>>;
  classes: { [key: string]: string };
} & PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface>;

const ReferencedInputs: FC<ReferencedInputsProps> = props => {
  const dataProvider = useDataProvider();
  const { values } = useFormState();

  const [routes, setRoutes] = useState<RouteDto[]>([]);

  const getRoutes = async e => {
    const routes = await findRouteByRegion(dataProvider, e.target.value);
    setRoutes(routes);
  };

  useEffect(() => {
    const e = {
      target: {
        value: props.record?.regionId,
      },
    };
    getRoutes(e);
  }, []);

  return (
    <Box display="flex">
      <ReferenceInput
        perPage={Number.MAX_SAFE_INTEGER}
        className={props.classes.input}
        fullWidth
        label="Builder"
        source="builderId"
        reference="builder"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        perPage={Number.MAX_SAFE_INTEGER}
        sort={{ field: 'name', order: 'ASC' }}
        className={props.classes.input}
        fullWidth
        onChange={e => getRoutes(e)}
        label="Region"
        source="regionId"
        reference="region"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RouteInput
        classes={props.classes}
        source="routeId"
        regionId={values.regionId}
        onChange={e => {
          props.setSelectedRoute(routes.find(route => route.id === e) ?? null);
        }}
        options={routes}
      />
      <BooleanInput label="Active" source="active" />
    </Box>
  );
};

const createTableData = ({
  id,
  name,
  active,
}: SpMatchesDefaultTableRow): SpMatchesDefaultTableRow => {
  return { id, name, active };
};

export const SubDivisionsEdit: FC<EditProps> = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [loading, setLoading] = useState(false);
  const [spSubdivisions, setSpSubdivisions] = useState<
    SpSubdivisionNameInterface[] | null
  >(null);
  const [showEditSubdivisionsNameModal, setShowEditSubdivisionsNameModal] =
    useState(false);
  const [error, setError] = useState<SpMatchesError | null>(null);

  const [builderSupervisors, setBuilderSupervisors] = useState<any>([]);
  const [subdivision, setSubdivision] = useState<SubdivisionDto | null>();
  const [mappedBuilderSupervisors, setMappedBuilderSupervisors] = useState<any>(
    [],
  );
  const [selectedRoute, setSelectedRoute] = useState<RouteDto | null>(null);

  const classes = useStyles();

  const builderSupervisorsDictionary = useMemo(
    () => keyBy(builderSupervisors, 'id'),
    [builderSupervisors],
  );

  const preSave = async (save, payload) => {
    const deleted: any = [];
    const added: any = [];

    const mappedBuilderSupervisorsIds = mappedBuilderSupervisors.map(
      builderSupervisors => builderSupervisors.id,
    );

    subdivision?.subdivisionBuilderSupervisors?.forEach((item: any) => {
      if (mappedBuilderSupervisorsIds.indexOf(item.userId) === -1) {
        deleted.push(item?.id);
      }
    });
    mappedBuilderSupervisorsIds.forEach(item => {
      const findAdded = subdivision?.subdivisionBuilderSupervisors?.find(
        (itemSupervisor: any) => itemSupervisor.userId === item,
      );
      if (!findAdded) {
        added.push({
          userId: item,
          subdivisionId: subdivision?.id,
        });
      }
    });

    try {
      await batchBuilderSupervisor({
        insert: added,
        update: [],
        delete: deleted,
      });
      save(payload);
    } catch (err: any) {
      notify(err.response.data.message, 'warning');
    }
  };

  const openCancelConfirmationModal = () =>
    setShowEditSubdivisionsNameModal(true);

  const closeCancelConfirmationModal = () =>
    setShowEditSubdivisionsNameModal(false);

  const getSpSubvidisions = async () => {
    setLoading(true);

    try {
      const spSubdivisions =
        await dataProvider.getList<SpSubdivisionNameInterface>(
          'sp-subdivision-name',
          {
            filter: { subdivisionId: props.id },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'spName', order: 'ASC' },
          },
        );

      if (spSubdivisions?.data) {
        setSpSubdivisions(spSubdivisions.data);
      }
    } catch (err) {
      notify('Failed to load SupplyPro subdivision names.', 'error');
    }

    setLoading(false);
  };

  const onUpdateActiveFlag = async (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (!spSubdivisions) return;

    const rowIndex = spSubdivisions.findIndex(row => row.id === id);

    if (rowIndex < 0) return;

    const newSpSubdivisions = [...spSubdivisions];
    const newRow = {
      ...spSubdivisions[rowIndex],
      active: event.target.checked,
    };

    newSpSubdivisions[rowIndex] = newRow;

    try {
      await dataProvider.update('sp-subdivision-name', {
        id,
        data: {
          active: newRow.active,
        },
        previousData: spSubdivisions[rowIndex],
      });

      setSpSubdivisions(newSpSubdivisions);
      notify('SupplyPro subdivision name updated!', 'success');
    } catch (err) {
      notify('Failed to update SupplyPro subdivision name.', 'error');
    }
  };

  const onAddNewItem = async (spName: string, active: boolean) => {
    const spNameTrimmed = spName.trim();

    if (spNameTrimmed.length === 0) {
      setError({ message: 'Subdivision name cannot be empty.' });
      return;
    }

    try {
      const { data } = await dataProvider.create<SpSubdivisionNameInterface>(
        'sp-subdivision-name',
        {
          data: {
            subdivisionId: props.id,
            spName: spNameTrimmed,
            active,
            builderId: subdivision?.builderId,
          },
        },
      );

      setSpSubdivisions(prevState => {
        if (!prevState) {
          return [data];
        }

        return [...prevState, data];
      });

      notify('SupplyPro subdivision name created!', 'success');
      return data;
    } catch (err) {
      notify('Failed to add a new SupplyPro subdivision name.', 'error');
    }
  };

  const rows: SpMatchesDefaultTableRow[] | null =
    spSubdivisions?.map(subdivision =>
      createTableData({
        id: subdivision.id,
        name: subdivision.spName,
        active: subdivision.active,
      }),
    ) || null;

  const getSubdivision = async () => {
    if (!props.id) {
      return;
    }
    try {
      const subFind = await dataProvider.getOne<SubdivisionDto>('subdivision', {
        id: props.id,
      });

      if (subFind?.data && subFind.data) {
        setSubdivision(subFind.data);
        callGetBuildSupervisors(subFind.data.regionId);
      }
    } catch (err) {
      notify('Failed to load SupplyPro model names.', 'error');
    }
  };

  const callGetBuildSupervisors = async regionId => {
    const response = await getBuilderSupervisors(regionId);
    setBuilderSupervisors(response.data.data);
  };

  useEffect(() => {
    getSpSubvidisions();
    getSubdivision();
  }, [dataProvider]);

  useEffect(() => {
    if (
      !Object.values(builderSupervisorsDictionary)?.length ||
      !subdivision?.subdivisionBuilderSupervisors?.length
    )
      return;

    const currentMappedBuilderSupervisors =
      subdivision.subdivisionBuilderSupervisors.map(item => {
        return {
          name: `${builderSupervisorsDictionary[item.userId]?.firstName} ${
            builderSupervisorsDictionary[item.userId]?.lastName
          }`,
          id: item.userId,
        };
      }) ?? [];

    setMappedBuilderSupervisors(currentMappedBuilderSupervisors);
  }, [subdivision, builderSupervisors]);

  return (
    <>
      <Edit {...props} mutationMode="pessimistic" className={classes.createBox}>
        <SimpleForm
          validate={validateSubdivisionCreation}
          initialValues={{
            builderSupervisors: mappedBuilderSupervisors,
          }}
          presave={preSave}
        >
          <ReferencedInputs
            classes={classes}
            setSelectedRoute={setSelectedRoute}
          />
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="name" />
            <TextInput
              fullWidth
              className={classes.input}
              source="idCode"
              label="ID Code"
            />
          </Box>
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="address1" />
            <TextInput fullWidth className={classes.input} source="address2" />
          </Box>
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="city" />
            <TextInput fullWidth className={classes.input} source="zip" />
            <State fullWidth className={classes.input} source="state" />
          </Box>
          <Box className={classes.flexFields}>
            <SelectInput
              fullWidth
              source="warrantyStatus"
              label="Warranty Status"
              className={classes.thirdWidth}
              choices={WarrantyStatusChoices}
            />

            <SupervisorInput
              classes={classes}
              source="supervisorUserId"
              onChange={() => ({})}
              value={selectedRoute?.supervisorUserId}
            />
          </Box>
          <Box mx={2} className={classes.builderSupervisorContainer}>
            <Autocomplete
              fullWidth
              multiple
              getOptionLabel={option => (option ? option?.name : '')}
              renderInput={params => (
                <TextField {...params} label="Builder Supervisors" />
              )}
              options={builderSupervisors.map(item => ({
                name: `${item.firstName} ${item.lastName}`,
                id: item.id,
              }))}
              value={mappedBuilderSupervisors}
              onChange={(_event, value, _reason, _details) => {
                const uniqValues = uniqBy(value, item => item.id);

                setMappedBuilderSupervisors(uniqValues);
              }}
            />
          </Box>
          <BoardCostFields isEdit />
          {hasPermission(CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW) && (
            <BumpoutTimesheetBooleanInput label="Follow Bumpout Timesheets Flow" />
          )}
        </SimpleForm>
      </Edit>

      {hasPermission(CAN_SEE_SP_POTENTIAL_MATCHES) && (
        <>
          {loading ? (
            <Loading loadingPrimary="" loadingSecondary="" />
          ) : (
            <Box className={classes.subdivisionsMatchesBox}>
              <Typography
                className={classes.subdivisionsHeading}
                variant="h6"
                component="h3"
              >
                SupplyPro - Potential Matches
              </Typography>

              <SpMatchesDefaultTableList
                columns={['SupplyPro Potential Matches']}
                rows={rows}
              />

              {hasPermission(CAN_EDIT_SP_POTENTIAL_MATCHES) && (
                <>
                  <SpMatchesDefaultModal
                    open={showEditSubdivisionsNameModal}
                    onClose={closeCancelConfirmationModal}
                    error={error}
                    columns={['SupplyPro Potential Matches']}
                    onUpdateActiveFlag={onUpdateActiveFlag}
                    onAddNewItem={onAddNewItem}
                    rows={rows}
                  />

                  <EditButton
                    variant="contained"
                    size="medium"
                    onClick={openCancelConfirmationModal}
                    className={classes.editButton}
                  />
                </>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  addBox: {
    display: 'flex',
    paddingTop: '12px',
    cursor: 'pointer',
  },
  inputSupervisor: {
    margin: '0px 15px 25px!important',
  },
  flexFields: {
    width: '100%',
    display: 'flex',
  },
  subdivisionsMatchesBox: {
    maxWidth: '500px',
    marginTop: '24px',
  },
  editButton: {
    marginTop: '24px',
  },
  subdivisionsHeading: {
    marginBottom: '16px',
  },
  builderSupervisorContainer: {
    display: 'flex',
    width: 'auto',
  },
  bumpoutTimesheetFlow: {
    margin: '20px 15px',
  },
  numberFields: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    paddingLeft: 15,
  },
  numberFieldsMobile: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    padding: '0 15px',
    flexWrap: 'wrap',
  },
  phaseCostInput: {
    minWidth: '180px',
    width: '100%',
  },
});
