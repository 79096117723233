import React, {
  FC,
  useEffect,
  useState,
  cloneElement,
  Fragment,
  ReactElement,
} from 'react';
import {
  List,
  TextField,
  NumberField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
  ExportButton,
  FilterProps,
  ListProps,
  PublicFieldProps,
  ToolbarProps,
  ExportButtonProps,
  InjectedFieldProps,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import Chip from '@material-ui/core/Chip';
import { UserInterface, getSupervisors } from 'services/regions';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_REGION } from 'providers/permissions';

const useStyles = makeStyles({
  rightMargin: {
    marginRight: '5px',
  },
});

const RegionsFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$cont" data-cy="regionsFilterName" />
    <TextInput
      label="Shortname"
      source="shortName||$cont"
      data-cy="regionsFilterShortname"
    />
  </Filter>
);

const Supervisors: FC<PublicFieldProps & InjectedFieldProps<UserInterface>> =
  props => {
    const classes = useStyles();

    const [regionalManagers, setRegionalManagers] = useState<UserInterface[]>(
      [],
    );

    const getUsers = async () => {
      if (!props.record) return;

      const users = await getSupervisors({
        regionId: props.record.id,
      });
      setRegionalManagers(users.data.data);
    };

    useEffect(() => {
      getUsers();
    }, []);

    return (
      <Box>
        {regionalManagers.map(regionalManager => (
          <Chip
            key={regionalManager.id}
            className={classes.rightMargin}
            label={`${regionalManager.firstName} ${regionalManager.lastName}`}
          />
        ))}
      </Box>
    );
  };

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_REGION) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const RegionsList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('regions')}
      bulkActionButtons={<React.Fragment />}
      filters={<RegionsFilter>{props.filters}</RegionsFilter>}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<ListActions />}
    >
      <Datagrid rowClick={hasPermission(CAN_EDIT_REGION) ? () => 'edit' : ''}>
        <TextField source="name" />
        <TextField source="shortName" label="Short Name" />
        <NumberField source="repairsRate" label="Repair Rate (Per Hour)" />
        <NumberField
          source="garageCostPerBoard"
          label="Garage Cost (Per Board)"
        />
        <NumberField
          source="hangingCostPerBoard"
          label="Hanging Cost (Per Board)"
        />
        <NumberField
          source="finishingCostPerBoard"
          label="Finishing Cost (Per Board)"
        />
        <NumberField
          source="sprayingCostPerBoard"
          label="Spraying Cost (Per Board)"
        />
        <Supervisors label="Manager" />
        {hasPermission(CAN_EDIT_REGION) && <EditButton />}
      </Datagrid>
    </List>
  );
};
