import { useState } from 'react';
import {
  PublicFieldProps,
  InjectedFieldProps,
  BooleanInput,
  NumberInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { SubdivisionInterface } from '@vatos-pas/common';
import { Show } from 'components/Show';
import isMobileHook from './../../../hooks/isMobile';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import { handleDecimalPlaces } from 'utils';

type BoardCostFieldsProps = PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface> & {
    isEdit?: boolean;
  };

const BoardCostFields = ({
  isEdit = false,
  ...props
}: BoardCostFieldsProps) => {
  const isMobile = isMobileHook();
  const { values } = useFormState();
  const classes = useStyles();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(
    isEdit ? !!values?.overrideRegionCostPerBoard : false,
  );

  const handleChange = (checked: boolean) => {
    setIsConfirmModalOpen(checked);
  };

  return (
    <Box>
      <BooleanInput
        {...props}
        fullWidth
        onChange={handleChange}
        className={classes.enableCostPerBoard}
        source="overrideRegionCostPerBoard"
        label="Override Region QB Integration cost"
      />
      <Show condition={isConfirmModalOpen}>
        <Box
          className={
            isMobile ? classes.numberFieldsMobile : classes.numberFields
          }
        >
          <NumberInput
            className={classes.phaseCostInput}
            min={0}
            step={0.01}
            label="Garage (Cost per board)"
            source="garageCostPerBoard"
            parse={handleDecimalPlaces}
            {...props}
          />
          <NumberInput
            className={classes.phaseCostInput}
            min={0}
            step={0.01}
            label="Hanging (Cost per board)"
            source="hangingCostPerBoard"
            parse={handleDecimalPlaces}
            {...props}
          />
          <NumberInput
            className={classes.phaseCostInput}
            min={0}
            step={0.01}
            label="Finishing (Cost per board)"
            source="finishingCostPerBoard"
            parse={handleDecimalPlaces}
            {...props}
          />
          <NumberInput
            className={classes.phaseCostInput}
            min={0}
            step={0.01}
            label="Spraying (Cost per board)"
            source="sprayingCostPerBoard"
            parse={handleDecimalPlaces}
            {...props}
          />
        </Box>
      </Show>
    </Box>
  );
};

const useStyles = makeStyles({
  numberFields: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    paddingLeft: 15,
  },
  numberFieldsMobile: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    padding: '0 15px',
    flexWrap: 'wrap',
  },
  phaseCostInput: {
    minWidth: '180px',
    width: '100%',
  },
  enableCostPerBoard: {
    margin: '20px 15px',
  },
});

export default BoardCostFields;
