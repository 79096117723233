import React, { useEffect, useState } from 'react';
import {
  Edit,
  TextInput,
  NumberInput,
  EditProps,
  Loading,
  useDataProvider,
  useNotify,
  EditButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RegionTimeWindowDto } from '@vatos-pas/common';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import { validateRegion } from './regionValidation';
import SimpleForm from 'components/SimpleForm';
import { UserInterface, getSupervisors } from 'services/regions';
import { RegionTimeWindowModal } from 'components/RegionTimeWindowTable/RegionTimeWindowModal';
import { Show } from 'components/Show';
import { RegionTimeWindowList } from 'components/RegionTimeWindowTable/RegionTimeWindowList';
import { RegionTimeWindowTableDataRow } from 'components/RegionTimeWindowTable/types';
import { TABLE_COLUMNS } from 'components/RegionTimeWindowTable/constants';
import isMobileHook from './../../../hooks/isMobile';
import { handleDecimalPlaces } from 'utils';

const createTableData = ({
  id,
  incrementalId,
  windowStart,
  windowEnd,
}: RegionTimeWindowTableDataRow): RegionTimeWindowTableDataRow => {
  return { id, incrementalId, windowStart, windowEnd };
};

const Supervisors = (props: EditProps) => {
  const [regionalManagers, setRegionalManagers] = useState<UserInterface[]>([]);

  const getUsers = async () => {
    if (!props.id) return;

    const users = await getSupervisors({
      regionId: props.id,
    });
    setRegionalManagers(users.data.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box width="100%" display="flex" flexDirection="column" mx={2}>
      <Typography variant="body1" gutterBottom className={props.classes.bold}>
        Regional Managers
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {regionalManagers.map(regionalManager => (
            <Chip
              key={regionalManager.id}
              className={props.classes.rightMargin}
              label={`${regionalManager.firstName} ${regionalManager.lastName}`}
            />
          ))}
        </Box>
        <NumberInput
          className={props.classes.inputNumber}
          min="1"
          max="100"
          step="0.01"
          label="Repair Rate (Per Hour)"
          source="repairsRate"
        />
      </Box>
    </Box>
  );
};

export const RegionsEdit = (props: EditProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const isMobile = isMobileHook();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regionTimeWindows, setRegionTimeWindows] = useState<
    RegionTimeWindowDto[] | null
  >(null);

  const getRegionTimeWindows = async () => {
    setLoading(true);

    try {
      const regionTimeWindows = await dataProvider.getList<RegionTimeWindowDto>(
        'region-time-window',
        {
          filter: {
            regionId: props.id,
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'windowStartTime', order: 'ASC' },
        },
      );

      if (regionTimeWindows?.data) {
        setRegionTimeWindows(regionTimeWindows.data);
      }
    } catch (err) {
      notify('Failed to load region time windows.', 'warning');
    }

    setLoading(false);
  };

  const rows: RegionTimeWindowTableDataRow[] | null =
    regionTimeWindows?.map((regionTimeWindow, index) => {
      return createTableData({
        id: regionTimeWindow.id,
        incrementalId: index + 1,
        windowStart: regionTimeWindow.windowStartTime,
        windowEnd: regionTimeWindow.windowEndTime,
      });
    }) || null;

  useEffect(() => {
    getRegionTimeWindows();
  }, []);

  return (
    <>
      <Edit {...props} className={classes.createBox} mutationMode="pessimistic">
        <SimpleForm validate={validateRegion}>
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="name" />
            <TextInput fullWidth className={classes.input} source="shortName" />
          </Box>
          <Box
            className={
              isMobile ? classes.phasesFieldsMobile : classes.phasesFields
            }
          >
            <NumberInput
              className={classes.phasesCostInput}
              min={0}
              step={0.01}
              label="Garage (Cost per board)"
              source="garageCostPerBoard"
              parse={handleDecimalPlaces}
            />
            <NumberInput
              className={classes.phasesCostInput}
              min={0}
              step={0.01}
              label="Hanging (Cost per board)"
              source="hangingCostPerBoard"
              parse={handleDecimalPlaces}
            />
            <NumberInput
              className={classes.phasesCostInput}
              min={0}
              step={0.01}
              label="Finishing (Cost per board)"
              source="finishingCostPerBoard"
              parse={handleDecimalPlaces}
            />
            <NumberInput
              className={classes.phasesCostInput}
              min={0}
              step={0.01}
              label="Spraying (Cost per board)"
              source="sprayingCostPerBoard"
              parse={handleDecimalPlaces}
            />
          </Box>
          <Box className={classes.fields}>
            <Supervisors {...props} classes={classes} />
          </Box>
        </SimpleForm>
      </Edit>

      <Show
        condition={!loading}
        fallback={<Loading loadingPrimary="" loadingSecondary="" />}
      >
        <Box className={classes.builderBoardTypeMatchesBox}>
          <Typography
            className={classes.builderBoardTypeHeading}
            variant="h6"
            component="h3"
          >
            H/O Service Time Windows
          </Typography>

          <Box maxWidth="480px">
            <RegionTimeWindowList
              rows={rows}
              regionId={props.id}
              columns={TABLE_COLUMNS}
            />
          </Box>

          <RegionTimeWindowModal
            open={open}
            rows={rows}
            regionId={props.id}
            columns={TABLE_COLUMNS}
            onClose={() => setOpen(false)}
            setRegionTimeWindows={setRegionTimeWindows}
          />
          <EditButton
            variant="contained"
            size="medium"
            onClick={() => setOpen(true)}
            className={classes.editButton}
          />
        </Box>
      </Show>
    </>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  inputNumber: {
    margin: '0px 15px 0px 200px',
    minWidth: '200px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  bold: {
    fontWeight: 'bold',
  },
  rightMargin: {
    marginRight: '5px',
  },
  builderBoardTypeMatchesBox: {
    marginTop: '24px',
  },
  editButton: {
    marginTop: '24px',
  },
  builderBoardTypeHeading: {
    marginBottom: '16px',
  },
  phasesCostInput: {
    minWidth: '180px',
    width: '100%',
  },
  phasesFieldsMobile: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    padding: '0 15px',
    flexWrap: 'wrap',
  },
  phasesFields: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    gap: 12,
    paddingLeft: 15,
  },
});
