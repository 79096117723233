const handleDecimalPlaces = value => {
  if (value !== undefined && value !== '') {
    // Parse the value and limit it to two decimals
    const parsedValue = Math.round(parseFloat(value) * 100) / 100;
    return isNaN(parsedValue) ? '' : parsedValue;
  }
  return '';
};

export default handleDecimalPlaces;
