import { getCoordinatorsByRegion } from 'services/subDivisions';

const ZIP_CODE_REGEX = new RegExp(/^\d{5}(-\d{4})?$/);

interface IErrors {
  name?: string[];
  address1?: string[];
  routeId?: string[];
  warrantyStatus?: string[];
  city?: string[];
  zip?: string[];
  state?: string[];
  idCode?: string[];
  supervisorUserId?: string[];
  garageCostPerBoard?: string[];
  hangingCostPerBoard?: string[];
  finishingCostPerBoard?: string[];
  sprayingCostPerBoard?: string[];
  overrideRegionCostPerBoard?: boolean;
}

interface ISupervisorValues {
  regionId: string;
  supervisorUserId: string;
}

const validateSupervisor = async ({
  regionId,
  supervisorUserId,
}: ISupervisorValues) => {
  const users = await getCoordinatorsByRegion({
    regionId,
  });
  const user = users.data.data.find(user => user.id === supervisorUserId);
  return user;
};

interface ISubdivisionValues extends ISupervisorValues {
  name: string;
  address1: string;
  active: boolean;
  warrantyStatus: string;
  routeId: string;
  city: string;
  zip: string;
  state: string;
  idCode: string;
  garageCostPerBoard: number;
  hangingCostPerBoard: number;
  finishingCostPerBoard: number;
  sprayingCostPerBoard: number;
  overrideRegionCostPerBoard: boolean;
}

export const validateSubdivisionCreation = async (
  values: ISubdivisionValues,
): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.address1) {
    errors.address1 = ['The Address is required'];
  }
  if (values.active && !values.routeId) {
    errors.routeId = ['The Route is required'];
  }
  if (!values.warrantyStatus) {
    errors.routeId = ['The Warranty Status is required'];
  }
  if (!values.city) {
    errors.city = ['The city is required'];
  }
  if (!values.zip) {
    errors.zip = ['The zip is required'];
  } else if (!values.zip.match(ZIP_CODE_REGEX)) {
    errors.zip = [
      'Zip code must be entered either in 5 digit format (12345) or 9 digit format (12345-1234)',
    ];
  }
  if (!values.state) {
    errors.state = ['The state is required'];
  }
  if (!values.idCode) {
    errors.idCode = ['The ID Code is required'];
  }
  if (values.regionId && values.supervisorUserId) {
    const isSupervisorValid = await validateSupervisor(values);
    if (!isSupervisorValid) {
      errors.supervisorUserId = ['The Supervisor is Invalid'];
    }
  }

  if (values.overrideRegionCostPerBoard) {
    if (values.garageCostPerBoard && values.garageCostPerBoard < 0) {
      errors.garageCostPerBoard = [
        'The Garage (Cost per board) must be at least 0',
      ];
    }

    if (values.hangingCostPerBoard && values.hangingCostPerBoard < 0) {
      errors.hangingCostPerBoard = [
        'The Hanging (Cost per board) must be at least 0',
      ];
    }

    if (values.finishingCostPerBoard && values.finishingCostPerBoard < 0) {
      errors.finishingCostPerBoard = [
        'The Finishing (Cost per board) must be at least 0',
      ];
    }

    if (values.sprayingCostPerBoard && values.sprayingCostPerBoard < 0) {
      errors.sprayingCostPerBoard = [
        'The Spraying (Cost per board) must be at least 0',
      ];
    }
  }

  return errors;
};
